import React from "react";
import { graphql } from "gatsby";
import LandingPage from "src/templates/landing-page";

export const query = graphql`
  query HomepageQuery {
    page: sanityLandingPage(_id: { regex: "/(drafts.|)homepage/" }) {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const IndexPage = (props: any) => {
  const { location } = props;
  const content = props.data.page.content;
  return <LandingPage location={location} pageContext={content} />;
};

export default IndexPage;
